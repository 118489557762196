import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Packaging materials`}</h4>
    <p>{`Semiconductor packaging involves several steps to bond a fabricated chip to an encasing package, each requiring different materials. For example, a bond wire, typically made of aluminum, copper, silver, or gold, attaches the chip to a lead frame. The lead frame transfers data between the chip and external devices. A protective ceramic package, plastic substrate, or encapsulant resin can also be bonded to the chip.  Die attach materials including polymers and eutectic alloys are used to attach the chip to packages or substrates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      